$primary-color: #752225;
$secondary-color: #e2cd93;
$third-color: #d0ad03;
$four-color: #b2cf7c;
$black-color: #000;
$white-color: #fff;
$gray-color: #eee;

$trans: 0.3s;

$fs-6: 0.35rem;
$fs-7: 0.45rem;
$fs-8: 0.55rem;
$fs-12: 0.75rem;
$fs-14: 0.875rem;
$fs-16: 1rem;
$fs-20: 1.25rem;
$fs-24: 1.5rem;
$fs-28: 1.75rem;
$fs-32: 2rem;
$fs-36: 2.25rem;
$fs-40: 2.5rem;
$fs-44: 2.75rem;
$fs-48: 3rem;
$fs-52: 3.25rem;
$fs-56: 3.5rem;
$fs-60: 3.75rem;
$fs-64: 4rem;
$fs-68: 4.25rem;
$fs-72: 4.5rem;
$fs-76: 5rem;
$fs-80: 6.5rem;
$fs-90: 6.5rem;
$fs-120: 8rem;
$fs-150: 13rem;
