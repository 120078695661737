@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
html {
    font-size: 16px;
}
body {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    font-size: $fs-16;
    overflow-x: hidden;
}
img {
    width: 100%;
    display: block;
}
a {
    text-decoration: none;
    color: $white-color;
}
input,
button,
textarea {
    font-family: inherit;
    border: none;
}
ul {
    list-style: none;
    margin-bottom: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 10px 0;
}
ul {
    padding-left: 0;
}
