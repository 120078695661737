.headerAdmin {
    background-color: $primary-color;
    color: $white-color;
    &-list {
        @include flexBox(space-between);
        &-header {
            &_child {
                margin: 0 $fs-16;
                padding: $fs-8;
                color: $white-color;
                font-weight: 600;
                transition: 0.5s;
                &:hover {
                    color: $primary-color;
                    background-color: $white-color;
                    padding: $fs-8;
                    border-radius: $fs-8;
                }
            }
        }
    }
    &-user {
        @include flexBox(space-between);
        h5 {
            font-weight: 300;
            font-size: $fs-16;
            margin-right: $fs-32;
            span {
                font-weight: 600;
            }
        }
        p {
            border: 1px solid $white-color;
            border-radius: $fs-8;
            padding: $fs-8;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                background-color: $white-color;
                color: $primary-color;
            }
            i {
                margin-left: $fs-12;
            }
        }
    }
}
