.recruitment {
    &-title {
        background-color: $secondary-color;
        padding: $fs-40;
        margin-top: $fs-28;
        h3 {
            margin-bottom: $fs-28;
            font-weight: 600;
            font-size: $fs-24;
        }
    }
    &-address {
        display: flex;
        align-items: center;
        margin-bottom: $fs-20;
        i {
            margin-right: 12px;
        }
        a {
            color: $black-color;
            transition: 0.5s;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    h2 {
        text-align: center;
        margin: $fs-52 0 $fs-20 0;
    }
    &-form {
        gap: 16px;
        width: 80%;
        margin: 0 auto;
        &_input {
            display: flex;
            flex-direction: column;
            label {
                font-weight: bold;
                margin-bottom: 8px;
            }

            input {
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 16px;
            }

            .error {
                color: red;
                font-size: 14px;
                margin-top: 4px;
            }
        }

        button {
            display: flex;
            justify-content: center;
            background-color: $primary-color;
            color: $white-color;
            border-radius: $fs-8;
            padding: 10px 10px;
            font-weight: 400;
            margin-top: $fs-20;
            margin: $fs-32 auto;
            width: 60%;
            font-size: $fs-16;
            transition: 0.5s;
            &:hover {
                color: $black-color;
                background-color: $secondary-color;
            }
            @media (max-width: 991px) {
                font-size: $fs-16;
            }

            &.secondary {
                background-color: transparent;
                color: $primary-color;
                border: 1px solid $primary-color;
                border-radius: $fs-12;
                transition: 0.5s;
                &:hover {
                    color: $black-color;
                    border: 1px solid $secondary-color;
                    background-color: $secondary-color;
                }
            }
        }
    }
}
