.login {
    margin-top: $fs-90;
    h2 {
        text-align: center;
    }
    &-form {
        width: 35%;
        margin: 0 auto;
        padding: $fs-20;
        border-radius: $fs-16;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        @media (max-width: 991px) {
            width: 45%;
        }
        @media (max-width: 767px) {
            width: 60%;
        }
        @media (max-width: 575px) {
            width: 80%;
        }
        @media (max-width: 400px) {
            width: 90%;
        }
        &_input {
            margin-top: $fs-28;
            label {
                font-weight: bold;
                margin-bottom: $fs-8;
            }
            input {
                width: 100%;
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 16px;
            }
        }
        button {
            display: flex;
            justify-content: center;
            background-color: $primary-color;
            color: $white-color;
            border-radius: $fs-8;
            padding: $fs-12;
            font-weight: 400;
            margin-top: $fs-20;
            margin: $fs-32 auto;
            width: 100%;
            font-size: $fs-16;
            transition: 0.5s;
            &:hover {
                color: $black-color;
                background-color: $secondary-color;
            }

            å &.secondary {
                background-color: transparent;
                color: $primary-color;
                border: 1px solid $primary-color;
                border-radius: $fs-12;
                transition: 0.5s;
                &:hover {
                    color: $black-color;
                    border: 1px solid $secondary-color;
                    background-color: $secondary-color;
                }
            }
        }
        &_errorMessage {
            color: red;
            margin-top: 10px;
            font-size: 14px;
        }
    }
}
