.list {
    background-color: #eee;
    &-menu {
        width: 95%;
        margin: 0 auto;
        padding: 20px;
        font-family: Arial, sans-serif;

        h2 {
            text-align: center;
            margin-bottom: 20px;
            color: #333;
        }
        &-input {
            @include flexBox(between);
            flex-wrap: wrap;
            gap: $fs-20;
            &_form {
                width: 24%;
                margin-bottom: $fs-20;
                select {
                    width: 100%;
                    padding: $fs-8;
                }
                input {
                    padding: 8px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    width: 100%;
                }
                &_error {
                    color: red;
                }
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border: 1px solid #ddd;
            margin-top: $fs-20;
            th {
                padding: 12px 15px;
                text-align: left;
                background-color: #f4f4f4;
                border-bottom: 2px solid #ddd;
                border-right: 1px solid #ddd;
                color: #555;
                width: 14%;
            }

            td {
                border-right: 1px solid #ddd;
                padding: 12px 15px;
                text-align: left;
                border-bottom: 1px solid #ddd;
            }

            tr {
                border-right: 1px solid #ddd;
                &:hover {
                    background-color: #f9f9f9;
                }

                img {
                    border-radius: 4px;
                }
            }
        }
    }
}
.button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 14px;
    transition: background-color 0.3s ease;
    &-delete,
    &-edit,
    &-add,
    &-update,
    &-cancel {
        margin-right: $fs-8;
        color: $white-color;
        background-color: $primary-color;
        border: 1px solid $primary-color;
        border-radius: $fs-8;
        padding: $fs-8;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
            color: $primary-color;
            background-color: transparent;
            border: 1px solid $primary-color;
            border-radius: $fs-8;
            padding: $fs-8;
        }
    }
    &:hover {
        background-color: #0056b3;
    }
}
