.orderproduct {
    margin: $fs-20 0;
    &-back {
        @include flexBox(left);
        margin-bottom: $fs-12;
        cursor: pointer;
        i {
            margin: 0 $fs-12 0 $fs-32;
        }
        p {
            font-weight: 500;
            font-size: $fs-20;
            font-style: italic;
            transition: 0.5s;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &-images {
        position: relative;
        img {
            border: 1px solid $primary-color;
            border-radius: $fs-12;
            width: 100%;
            margin: 0 auto;
        }
        p {
            @include posAbsolute("tr", 0, 10px);
            border-radius: $fs-8;
            background-color: $secondary-color;
            color: $black-color;
            padding: 6px 10px;
            font-size: $fs-16;
            animation: colorChange 2s infinite;
        }
    }
    &-choose {
        margin-top: $fs-32;
        @media (max-width: 991px) {
            margin-top: $fs-12;
        }
        h2 {
            color: $primary-color;
            font-size: $fs-48;
            @media (max-width: 991px) {
                font-size: $fs-36;
            }
        }

        p {
            font-size: $fs-16;
        }
        &_quantity {
            @include flexBox(left);
            display: inline-flex;
            border-radius: $fs-12;
            margin: $fs-32 0;
            i {
                padding: $fs-8 $fs-12;
                border-radius: $fs-12;
                border: 1px solid $primary-color;
                font-size: $fs-16;
                cursor: pointer;
            }
            h5 {
                padding: $fs-8 $fs-72;
                border-radius: $fs-12;
                border: 1px solid $primary-color;
                font-size: $fs-16;
                margin: 0 $fs-12;
            }
            @media (max-width: 991px) {
                font-size: $fs-12;
                margin: $fs-16 0;
                i {
                    font-size: $fs-12;
                }
                h5 {
                    font-size: $fs-12;
                }
            }
        }
    }
    &_button-1 {
        display: flex;
        justify-content: center;
        background-color: $primary-color;
        color: $white-color;
        border-radius: $fs-8;
        padding: 10px 10px;
        font-weight: 400;
        margin-top: $fs-20;
        font-size: $fs-20;
        transition: 0.5s;
        &:hover {
            color: $black-color;
            background-color: $secondary-color;
        }
        @media (max-width: 991px) {
            font-size: $fs-16;
        }

        &.secondary {
            background-color: transparent;
            color: $primary-color;
            border: 1px solid $primary-color;
            border-radius: $fs-12;
            transition: 0.5s;
            &:hover {
                color: $black-color;
                border: 1px solid $secondary-color;
                background-color: $secondary-color;
            }
        }
    }
}
