.about {
    img {
        width: 100%;
    }
    &-story {
        margin: $fs-32 0;
        text-align: center;
        @media (max-width: 575px) {
            margin: $fs-12 0;
        }
        @media (max-width: 434) {
            margin: $fs-8 0;
        }
        &_text {
            padding-top: $fs-48;
            @media (max-width: 991px) {
                padding-top: 0;
            }
            h2 {
                margin: $fs-20 0;
                font-weight: 600;
                font-size: $fs-20;
                @media (max-width: 767px) {
                    font-size: $fs-8;
                    margin: $fs-8 0;
                }
                span {
                    display: block;
                    font-size: $fs-14;
                    font-weight: 500;
                    margin-bottom: $fs-12;
                    letter-spacing: $fs-8;
                    @media (max-width: 767px) {
                        margin-bottom: $fs-8;
                        font-size: $fs-8;
                        display: inline;
                        font-weight: 600;
                        letter-spacing: 0;
                    }
                }
            }
            p {
                font-size: $fs-14;
                font-weight: 300;
                letter-spacing: 1px;
                @media (max-width: 767px) {
                    font-size: $fs-8;
                }
            }
        }
    }
    &-img {
        position: relative;
        img {
            border-radius: $fs-20;
        }
        &::after {
            @include posAbsolute("tl", 0, 0);
            @include beforeAfter(100%, 100%, #00000058);
            border-radius: $fs-20;
        }
    }
}
